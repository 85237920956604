/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////// WORK : DETAIL /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_work.view_detail';

/* ------------------------------------------------------------------- HEADER --- */
#{$h} body > header::before {
    background-color: transparent;
}

/* --------------------------------------------------------------------- MAIN --- */
#{$h} main {
    padding-top: 0;
}

/* ------------------------------------------------------------------ FEATURE --- */
#{$h} .feature {
    margin-bottom: 140px;
    @media (max-width: $breakpoint0) {
        margin-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 35) + 105px));
    }
    @media (max-width: $breakpoint3) {
        margin-bottom: 84px;
    }
}

/* ------------------------------------------------------------------- FOOTER --- */
#{$h} footer {
    display: none;
}
