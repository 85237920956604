/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// MAIN /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- MAIN --- */
main {
    position: relative;
    display: flex;
    padding-top: 220px;
    margin: 0 auto;
    flex: 1;
    flex-flow: column;
    @media (max-width: $breakpoint0) {
        padding-top: calc(((100vw - #{$breakpoint3}) * (124 / 1200)) + 96px);
    }
    @media (max-width: $breakpoint3) {
        padding-top: 60px;
    }
}
