/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// INDEX /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_index';

/* --------------------------------------------------------------------- MAIN --- */
#{$h} main {
    padding-top: 0;
}
