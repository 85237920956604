/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// MAP /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.map {
    position: relative;
    z-index: 3;
    height: calc((var(--vh, 1vh) * 100));
}


/* ------------------------------------------------------------------- CANVAS --- */
section.map .canvas {
    position: relative;
    width: 100vw;
    height: 100%;
    margin-left: calc(((50vw - 50%) * -1));
}
section.map .canvas .mapboxgl-control-container {
    display: none;
}
