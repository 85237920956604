/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// TYPOGRAPHY /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- DOCUMENT --- */
html,
body {
    line-height: 1;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* --------------------------------------------------------------------- BODY --- */
body {
    color: $grey9;
}

/* ------------------------------------------------------------------- ANCHOR --- */
a {
    color: inherit;
    text-decoration: none;
    transition: color 80ms ease;
    cursor: pointer;
}
