/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// MASK /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- MASK --- */
.mask {
    clip: rect(auto auto auto auto);
    animation-duration: 100ms;
    animation-iteration-count: infinite;
    animation-name: repaint;
    @include position-fill(absolute);
    @keyframes repaint {
        from {
            top: 0;
        }
        to {
            top: 0.001px;
        }
    }
}
