/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// HEADER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- HEADER --- */
body > header::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 128px;
    content: '';
    background-color: white;
    @media (max-width: $breakpoint0) {
        height: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 96px);
    }
    @media (max-width: $breakpoint3) {
        height: 60px;
        background-color: white;
        background-image: unset;
        filter: none;
    }
}

/* ---------------------------------------------------------------------- NAV --- */
header nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    width: 100%;
    height: 128px;
    align-items: center;
    justify-content: center;
    font-family: $family_serif;
    font-size: 19px;
    pointer-events: none;
    @media (max-width: $breakpoint0) {
        height: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 96px);
    }
    @media (max-width: $breakpoint3) {
        height: 60px;
        padding: 0 24px;
        justify-content: flex-start;
        transform: translateZ(0);
    }
}
body > header nav {
    transform: translateZ(0);
}
header nav a {
    position: relative;
    pointer-events: all;
}
header nav a::after {
    pointer-events: none;
}
header nav a:first-child {
    @media (max-width: $breakpoint3) {
        margin-right: 0 !important;
    }
}
header nav a:not(:first-child) {
    margin-top: 6px;
    @media (max-width: $breakpoint3) {
        display: none;
    }
}
html.active_nav header nav a:not(:first-child) {
    @media (max-width: $breakpoint3) {
        display: block;
    }
}
header nav a:not(:last-child) {
    margin-right: 0.25em;
}
header nav a:not(:first-child):not(:last-child)::after {
    content: '\2c';
}
header nav a:last-child::after {
    content: '\2e';
}
html.active_nav header nav {
    @media (max-width: $breakpoint3) {
        display: flex;
        height: 100%;
        color: white;
        background-color: $grey9;
    }
}
html.active_nav header nav a:first-child {
    @media (max-width: $breakpoint3) {
        position: absolute;
        top: 0;
        margin-top: 23px;
    }
}

/* ------------------------------------------------------------------ HEADING --- */
header h1 {
    font-family: $family_sans;
    letter-spacing: 0.01em;
    @include font-size(18px);
}

/* ------------------------------------------------------------------- SOCIAL --- */
header a.social {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;
    display: none;
    margin: 24px;
    color: white;
    font-family: $family_sans;
    @include font-size(18px);
}
header a.social::before {
    content: '@B_C_M_H';
}
html.active_nav header a.social {
    @media (max-width: $breakpoint3) {
        display: block;
    }
}

/* ------------------------------------------------------------------- TOGGLE --- */
header a.toggle {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    display: none;
    width: 25px;
    height: 25px;
    margin: 16px 24px 0;
    transition: unset;
    @media (max-width: $breakpoint3) {
        display: block;
    }
}
header a.toggle div,
header a.toggle div::before,
header a.toggle div::after {
    position: absolute;
    width: 100%;
    height: 1px;
}
header a.toggle div {
    top: 50%;
    transition-duration: 80ms;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
header a.toggle div::before,
header a.toggle div::after {
    display: block;
    content: '';
    background-color: currentColor;
}
header a.toggle div::before {
    top: -4.5px;
    transition: top 80ms 140ms;
}
header a.toggle div::after {
    bottom: -4.5px;
    transition: bottom 80ms 140ms, transform 80ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
html.active_nav header a.toggle {
    color: white;
}
html.active_nav header a.toggle div {
    transform: rotate(45deg);
    transition-delay: 140ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
html.active_nav header a.toggle div::before {
    top: 0;
    transition: top 80ms;
}
html.active_nav header a.toggle div::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 80ms, transform 80ms 140ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
