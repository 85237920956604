/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// DOCUMENT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- HTML --- */
html {
    height: 100%;
}
html.active_nav {
    overflow: hidden;
}

/* --------------------------------------------------------------------- BODY --- */
body {
    display: flex;
    min-height: 100%;
    justify-content: flex-start;
    flex-flow: column;
    background-color: white;
}
html.active_nav body {
    overflow: hidden;
}

/* ---------------------------------------------------------------- STRUCTURE --- */
main,
footer {
    width: calc(100% - 88px);
    max-width: 1520px;
    @media (max-width: $breakpoint1) {
        width: calc(100% - ((100vw - #{$breakpoint3}) * (12 / 720)) - 88px);
    }
    @media (max-width: $breakpoint3) {
        width: calc(100% - 48px);
    }
}
