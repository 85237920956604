/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// WORK /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- WORK --- */
.work {
    display: flex;
    padding-bottom: 256px;
    margin: 0 -10px;
    overflow: hidden;
    flex-wrap: wrap;
    @media (max-width: $breakpoint0) {
        padding-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 64) + 192px));
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 128px;
    }
    @media (max-width: $breakpoint3) {
        padding-bottom: 96px;
    }
}

/* ------------------------------------------------------------------ ARTICLE --- */
.work article {
    position: relative;
    width: calc((50% - 20px));
    margin: 0 10px;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    visibility: hidden;
    @keyframes reveal_work {
        0% {
            transform: translate3d(0, 100%, 0);
            opacity: 0;
        }
        100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
    @media (max-width: $breakpoint3) {
        width: 100%;
    }
}
.work article.active {
    animation-name: reveal_work;
    visibility: visible;
}
.work article:not(:nth-child(-n+2)) {
    @media (min-width: ($breakpoint0 + 1px)) {
        margin-top: 40px;
    }
}
.work article:not(:nth-child(-n+2)) {
    @media (max-width: $breakpoint0) {
        margin-top: 30px;
    }
}
.work article:not(:first-child) {
    @media (max-width: $breakpoint3) {
        margin-top: 20px;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
.work a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------ HEADING --- */
.work h2 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-family: $family_sans;
    line-height: 1.4;
}
.work h2::before {
    position: absolute;
    top: 28px;
    left: 0;
    z-index: -1;
    display: block;
    width: 1em;
    height: 32px;
    content: '';
    background-color: white;
    transform: translateX(-100%);
    @media (max-width: $breakpoint2) {
        height: 24px;
    }
    @media (max-width: $breakpoint3) {
        height: 21px;
    }
}
.work h2 span {
    position: relative;
}
.work h2 span.name,
.work h2 span.caption {
    @include font-size(18px);
}
.work h2 span.name {
    padding-right: 0.5em;
}
.work h2 span.service {
    flex-basis: 100%;
    font-family: $family_serif;
    @include font-size(19px);
}
.work h2 span.caption::before {
    position: absolute;
    left: -0.5em;
    z-index: -2;
    content: '\2c';
}

/* -------------------------------------------------------------------- MEDIA --- */
.work .media {
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: $breakpoint3) {
        margin-bottom: 10px;
    }
}
.work .media::before {
    display: block;
    padding-top: 65%;
    content: '';
}
.work .media.video::after {
    content: none;
}
