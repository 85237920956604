/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// FOOTER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- FOOTER --- */
footer {
    position: relative;
    display: flex;
    height: 256px;
    margin: 0 auto;
    margin-top: auto;
    align-items: flex-start;
    font-family: $family_sans;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include font-size(16px);
    @include background();
    @media (max-width: $breakpoint0) {
        height: calc(((((100vw - #{$breakpoint3}) / 720) * 64) + 128px));
    }
    @media (max-width: $breakpoint2) {
        height: 128px;
    }
    @media (max-width: $breakpoint3) {
        height: 96px;
        align-items: center;
        flex-flow: column;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
footer a {
    width: 33.333%;
    color: $grey9;
    text-align: center;
    @media (max-width: $breakpoint3) {
        width: unset;
    }
}
footer a:not(:last-child) {
    @media (max-width: $breakpoint2) {
        margin-bottom: 12px;
    }
    @media (max-width: $breakpoint3) {
        margin-bottom: 8px;
    }
}
