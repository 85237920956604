/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// VAR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- COLOR --- */
$grey3: #aaaaaa;
$grey6: #5c5c5c;
$grey9: #1e1e1e;

/* --------------------------------------------------------------- BREAKPOINT --- */
$breakpoint0: 1920px;
$breakpoint1: 1440px;
$breakpoint2: 1024px;
$breakpoint3: 720px;
$breakpoint4: 420px;
$breakpoint5: 370px;

/* ------------------------------------------------------------------- FAMILY --- */
$family_sans: ('unica', sans-serif);
$family_serif: ('freight', serif);
