/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// ERROR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- ERROR --- */
.error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-flow: column;
}

/* ------------------------------------------------------------------ HEADING --- */
.error h1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: $family_sans;
    line-height: 1.2;
    @include font-size(24px);
}
.error h1 span:last-child {
    margin-top: 4px;
    font-family: $family_serif;
}
.error h1 span:last-child::before {
    margin-right: 0.25em;
    content: '\2c';
}
