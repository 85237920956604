/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// NEWS /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ ARTICLE --- */
.news article {
    position: relative;
    display: grid;
    margin-bottom: 128px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    visibility: hidden;
    @keyframes reveal_news {
        0% {
            transform: translate3d(0, 50%, 0);
            opacity: 0;
        }
        100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
    @media (max-width: $breakpoint0) {
        margin-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 32) + 96px));
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 72px;
    }
    @media (max-width: $breakpoint3) {
        margin-bottom: 48px;
    }
}
.news article.light {
    color: $grey9;
}
.news article.dark {
    color: white;
}
.news article.active {
    animation-name: reveal_news;
    visibility: visible;
}

/* -------------------------------------------------------------------- MEDIA --- */
.news .media {
    width: 100%;
    grid-area: 1 / 1 / 2 / 2;
    @media (max-width: $breakpoint2) {
        height: 100%;
    }
}

/* -------------------------------------------------------------------- COLOR --- */
.news .color {
    width: 100%;
    grid-area: 1 / 1 / 2 / 2;
    @media (max-width: $breakpoint2) {
        height: 100%;
    }
}
.news .color::before {
    @media (min-width: $breakpoint2 + 1px) {
        display: block;
        padding-top: 56.25%;
        content: '';
    }
}

/* -------------------------------------------------------------------- LAYER --- */
.news .layer {
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 32px 10%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    grid-area: 1 / 1 / 2 / 2;
    font-family: $family_sans;
    text-align: center;
    @media (max-width: $breakpoint2) {
        min-height: calc(((100vw - ((100vw - 720px) * (312 / 1200)) - 88px) * 0.5625));
    }
    @media (max-width: $breakpoint3) {
        min-height: calc(((100vw - 48px) * 0.5625));
    }
}

/* ---------------------------------------------------------------------- SVG --- */
.news svg {
    width: auto;
    max-width: 100%;
}

/* ------------------------------------------------------------------ HEADING --- */
.news h2 {
    letter-spacing: -0.02em;
    @include font-size(80px);
}

/* --------------------------------------------------------------------- TEXT --- */
.news .text p {
    line-height: 1.3;
    @include font-size(30px);
}

/* ------------------------------------------------------------------- ANCHOR --- */
.news a {
    position: relative;
    margin-top: 48px;
    @include font-size(30px);
    @media (pointer: fine) {
        padding-bottom: 10px;
    }
    @media (max-width: $breakpoint2) {
        margin-top: 32px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 24px;
    }
}
.news a::before,
.news a::after {
    position: absolute;
    content: '';
}
.news a::before {
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 160ms;
}
.news a:hover::before {
    @media (pointer: fine) {
        width: 100%;
    }
}
.news a::after {
    top: 50%;
    left: 100%;
    width: 28px;
    height: 24px;
    margin-left: 0.25em;
    background-image: url('../gfx/arrow.png');
    background-position: top;
    background-size: 100% auto;
    transform: translateY(-50%);
    pointer-events: none;
    @media (max-width: $breakpoint2) {
        width: 22px;
        height: 19px;
        margin-top: -3px;
    }
    @media (max-width: $breakpoint3) {
        width: 18px;
        height: 15px;
    }
}
.news article.dark a::after {
    background-position: bottom;
}
.news a[target=_blank]::after {
    transform: translateY(-50%) rotate(-45deg);
}

/* --------------------------------------------------------------------- TIME --- */
.news time {
    margin-top: calc((40px - 1em));
    grid-area: 2 / 1 / 3 / 2;
    color: $grey3;
    font-family: $family_sans;
    letter-spacing: 0.1em;
    @include font-size(12px);
    @media (max-width: $breakpoint3) {
        margin-top: calc((32px - 1em));
    }
}

/* ------------------------------------------------------------------ CAPTION --- */
.news .caption {
    padding: 0 10%;
    margin-top: 48px;
    grid-area: 3 / 1 / 4 / 2;
    color: $grey9;
    font-family: $family_sans;
    line-height: 1.3;
    text-align: center;
    @include font-size(24px);
    @media (max-width: $breakpoint2) {
        margin-top: 32px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 24px;
    }
}
