/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// CONTENT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
.content section {
    position: relative;
}
.content section:first-child {
    margin-top: 0 !important;
}

/* ----------------------------------------------------------- SECTION : TEXT --- */
.content section.text {
    z-index: 1;
    display: flex;
    margin-top: 160px;
    justify-content: center;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        display: block;
        margin-top: 72px;
    }
}
.content section.text h2,
.content section.text p {
    font-family: $family_sans;
}
.content section.text h2 {
    width: 468px;
    padding-right: 2em;
    margin-top: -0.2em;
    flex: 0 0 auto;
    line-height: 1.4;
    @include font-size(18px);
    @media (max-width: $breakpoint0) {
        width: calc(((100vw - #{$breakpoint3}) * (234 / 1200)) + 234px);
    }
    @media (max-width: $breakpoint3) {
        width: unset;
        margin-top: unset;
        margin-bottom: 24px;
    }
}
.content section.text p {
    width: 80%;
    margin: 0 auto;
    line-height: 1.3;
    text-align: center;
    @include font-size(30px);
    @media (max-width: $breakpoint3) {
        width: unset;
    }
}
.content section.text p a {
    position: relative;
    white-space: nowrap;
}
.content section.text p a::before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    content: '';
    background-color: $grey9;
    transition: width 160ms;
}
.content section.text p a:hover::before {
    @media (pointer: fine) {
        width: 100%;
    }
}
.content section.text h2 + p {
    width: auto;
    flex: 1;
    font-family: $family_serif;
    line-height: 1.4;
    text-align: left;
    @include font-size(19px);
}

/* -------------------------------------------------------- SECTION : PICTURE --- */
.content section.text + section.picture {
    margin-top: 160px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 72px;
    }
}
.content section.video + section.picture,
.content section.picture + section.picture {
    margin-top: 200px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (40 / 1200)) + 160px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 128px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 96px;
    }
}
.content section.link + section.picture {
    margin-top: 240px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (48 / 1200)) + 192px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 144px;
    }
    @media (max-width: $breakpoint2) {
        margin-top: 112px;
    }
}
.content section.picture.banner {
    width: 100vw;
    margin-left: calc((50vw - 50%) * -1);
}
.content section.picture.gallery {
    z-index: 1;
    display: flex;
    margin: 0 -10px;
    align-items: flex-start;
    @media (max-width: $breakpoint3) {
        display: block;
        margin: 0;
    }
}
.content section.picture.frame .media,
.content section.picture.banner .media {
    z-index: 3;
}
.content section.picture.gallery .media {
    margin: 0 10px;
    flex: 1;
    @media (max-width: $breakpoint3) {
        margin: 0;
    }
}
.content section.picture.gallery .media:last-child {
    @media (max-width: $breakpoint3) {
        margin-top: 24px;
    }
}
.content section.picture.banner.dark header nav a,
.content section.picture.banner.dark header a.toggle {
    color: white;
}
.content section.picture.banner a.next {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 4;
    padding-bottom: 10px;
    margin-bottom: 100px;
    color: $grey9;
    transform: translateX(-50%);
    @media (max-width: $breakpoint0) {
        margin-bottom: 75px;
    }
    @media (max-width: $breakpoint3) {
        margin-bottom: 50px;
    }
}
.content section.picture.banner.dark a.next {
    color: white;
}
.content section.picture.banner a.next::before {
    content: 'Next Project';
    font-family: $family_sans;
    @include font-size(24px);
}
.content section.picture.banner a.next::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    content: '';
    background-color: currentColor;
    transition: width 160ms;
}
.content section.picture.banner a.next:hover::after {
    @media (pointer: fine) {
        width: 100%;
    }
}

/* ---------------------------------------------------------- SECTION : VIDEO --- */
.content section.video {
    position: relative;
}
.content section.text + section.video {
    margin-top: 160px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 72px;
    }
}
.content section.video + section.video,
.content section.picture + section.video {
    margin-top: 200px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (40 / 1200)) + 160px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 128px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 96px;
    }
}
.content section.link + section.video {
    margin-top: 240px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (48 / 1200)) + 192px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 144px;
    }
    @media (max-width: $breakpoint2) {
        margin-top: 112px;
    }
}
.content section.video::before {
    display: block;
    padding-top: 56.25%;
    content: '';
}
.content section.video.background {
    width: 100vw;
    margin-left: calc((50vw - 50%) * -1);
}
.content section.video .media.video {
    @include position-fill(absolute);
}
.content section.video.background .media.video::after {
    content: none;
}

/* ----------------------------------------------------------- SECTION : LINK --- */
.content section.link {
    z-index: 1;
    display: flex;
    justify-content: center;
}
.content section.picture + section.link {
    margin-top: 160px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 128px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 96px;
    }
}
.content section.link a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $family_sans;
    text-align: center;
    @include font-size(30px);
    @media (pointer: fine) {
        padding-bottom: 10px;
    }
}
.content section.link a::before {
}
.content section.link a::before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    content: '';
    background-color: $grey9;
    transition: width 160ms;
}
.content section.link a:hover::before {
    @media (pointer: fine) {
        width: calc(100% - 1.125em);
    }
}
.content section.link a::after {
    display: block;
    width: 0.875em;
    height: 0.75em;
    margin-top: 0.125em;
    margin-left: 0.25em;
    flex: 0 0 auto;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-position: top;
    background-size: 100% auto;
    pointer-events: none;
}
.content section.link a[target=_blank]::after {
    transform: rotate(-45deg);
}
