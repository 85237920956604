/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// MIXIN /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------- BACKGROUND --- */
@mixin background() {
    &::after {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        content: '';
        background: inherit;
        transform: translateX(-50%);
    }
    & > * {
        position: relative;
        z-index: 1;
    }
}

/* ---------------------------------------------------------------- FONT SIZE --- */
@mixin font-size($value) {
    font-size: $value;
    @media (max-width: $breakpoint3) {
        font-size: round(($value * 0.85));
    }
    @media (max-width: $breakpoint5) {
        @if $value > 20 {
            font-size: round(($value * pow(0.85, 2)));
        }
    }
}

/* --------------------------------------------------------------------- FILL --- */
@mixin position-fill($position) {
    position: $position;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* -------------------------------------------------------------------- POW --- */
@function pow($base, $exponents) {
    $raised: 1;
    @for $i from 1 through $exponents {
        $raised: ($raised * $base);
    }
    @return $raised;
}
