/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// FONT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- UNICA --- */
@font-face {
    font-family: 'unica';
    font-style: normal;
    font-weight: normal;
    src: url('../font/unica.woff2') format('woff2'),
		 url('../font/unica.woff') format('woff');
}

/* ------------------------------------------------------------------ FREIGHT --- */
@font-face {
    font-family: 'freight';
    font-style: normal;
    font-weight: normal;
    src: url('../font/freight.woff2') format('woff2'),
		 url('../font/freight.woff') format('woff');
}
