/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// ERROR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_error';

/* --------------------------------------------------------------------- MAIN --- */
#{$h} main {
    padding-top: 0;
}
