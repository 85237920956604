/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// ABOUT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_about';

/* --------------------------------------------------------------------- BODY --- */
#{$h} body {
    color: white;
    background-color: $grey9;
}

/* ------------------------------------------------------------------- HEADER --- */
#{$h} body > header::before {
    background-color: $grey9;
}
#{$h} header::before {
    background-image: linear-gradient(to bottom, $grey9 0%, $grey9 75%, rgba($grey9, 0) 100%);
}

/* ------------------------------------------------------------------ CONTENT --- */
#{$h} .content {
    padding-bottom: 44px;
    @media (max-width: $breakpoint0) {
        padding-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 20) + 24px));
    }
    @media (max-width: $breakpoint3) {
        padding-bottom: 24px;
        margin-top: 48px;
    }
}
#{$h} .content section.text p a {
    margin-right: 0;
}
#{$h} .content section.text p a::before {
    display: block;
    height: 1px;
    margin-bottom: 4px;
    background-color: white;
    @media (max-width: $breakpoint3) {
        margin-bottom: 2px;
    }
}
#{$h} .content section.text p a::after {
    content: none;
}
#{$h} .content  section.client + section.picture {
    margin-top: 160px;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 72px;
    }
}

/* ------------------------------------------------------------------- CLIENT --- */
#{$h} .client {
    display: flex;
    width: 80%;
    margin: 160px auto 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        width: unset;
        margin-top: 72px;
    }
}
#{$h} .client .item {
    width: auto;
    height: 128px;
    background-color: white;
    mask-image: var(--mask-image);
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    @media (max-width: $breakpoint3) {
        width: 25%;
        height: auto;
    }
}
#{$h} .client img {
    display: block;
    width: auto;
    height: 100%;
    max-width: 100%;
    opacity: 0;
}

/* ------------------------------------------------------------------- CREDIT --- */
#{$h} a.credit {
    display: block;
    margin-top: 160px;
    color: $grey6;
    font-family: $family_sans;
    @include font-size(18px);
    @media (max-width: $breakpoint0) {
        margin-top: calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 128px);
    }
    @media (max-width: $breakpoint2) {
        margin-top: 100px;
    }
    @media (max-width: $breakpoint3) {
        margin-top: 72px;
    }
}

/* ------------------------------------------------------------------- FOOTER --- */
#{$h} footer a {
    color: $grey3;
}
