/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// CONTACT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ CONTACT --- */
.contact {
    display: flex;
    padding-bottom: 128px;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-flow: column;
    font-family: $family_sans;
    line-height: 1.3;
    text-align: center;
    @include font-size(30px);
    @media (max-width: $breakpoint0) {
        padding-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 32) + 96px));
    }
    @media (max-width: $breakpoint2) {
        margin: 60p 0;
    }
    @media (max-width: $breakpoint3) {
        padding-bottom: 48px;
        margin: 48px 0;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
.contact a.address,
.contact a.email {
    margin-bottom: 60px;
    @media (max-width: $breakpoint0) {
        margin-bottom: calc(((((100vw - #{$breakpoint3}) / 720) * 20) + 40px));
    }
    @media (max-width: $breakpoint3) {
        margin-bottom: 20px;
    }
}
