/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// FEATURE /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ FEATURE --- */
.feature {
    position: relative;
    width: 100vw;
    margin-left: calc((50vw - 50%) * -1);
    overflow: hidden;
    color: white;
    font-family: $family_sans;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: $breakpoint3) {
        height: calc(var(--vh, 1vh) * 100);
    }
}
.feature::before {
    display: block;
    padding-top: 56.25%;
    content: '';
    @media (max-width: $breakpoint3) {
        content: none;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
.feature > a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

/* -------------------------------------------------------------------- MEDIA --- */
.feature .mobile,
.feature .desktop {
    display: none;
}
.feature .mobile {
    @media (max-width: $breakpoint4) {
        display: block;
    }
}
.feature .desktop {
    @media (min-width: ($breakpoint4 + 1px)) {
        display: block;
    }
}
.feature .media.image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    @media (max-width: $breakpoint4) {
        height: calc(var(--vh, 1vh) * 100);
    }
}
.feature .media.video {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc((16 / 9) * (var(--vh, 1vh) * 100));
    height: calc(var(--vh, 1vh) * 100);
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
    @media (min-aspect-ratio: 16 / 9) {
        width: 100vw;
        height: calc((9 / 16) * 100vw);
    }
}
.feature .media.video::after {
    content: unset;
}
.feature .media:nth-of-type(1) {
    z-index: 1;
}
.feature .media:nth-of-type(2) {
    z-index: 3;
    clip: rect(0, 100vw, 128px, 0);
    @media (max-width: $breakpoint0) {
        clip: rect(0, 100vw, calc(((100vw - #{$breakpoint3}) * (32 / 1200)) + 96px), 0);
    }
    @media (max-width: $breakpoint3) {
        clip: rect(0, 100vw, 60px, 0);
    }
}

/* ------------------------------------------------------------------ HEADING --- */
.feature h2 {
    z-index: 2;
    display: block;
    text-align: center;
    @include font-size(30px);
}
section.feature h2 {
    position: fixed;
    top: 28.125vw;
    left: 0;
    width: calc(100% - 40px);
    margin: 0 20px;
    letter-spacing: -0.02em;
    transform: translateY(-50%);
    @include font-size(60px);
    @media (max-width: $breakpoint3) {
        top: 50%;
    }
}
section.feature h2 em {
    font-family: $family_serif;
}
article.feature h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-bottom: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    pointer-events: none;
}
article.feature h2::before,
article.feature h2::after {
    position: absolute;
    display: block;
    content: '';
}
article.feature h2::before {
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 160ms;
}
article.feature a:hover ~ h2::before {
    @media (pointer: fine) {
        width: 100%;
    }
}
article.feature h2::after {
    top: 50%;
    left: 100%;
    width: 28px;
    height: 24px;
    margin-top: -2px;
    margin-left: 0.25em;
    background-image: url('../gfx/arrow.png');
    background-position: bottom;
    background-size: 100% auto;
    transform: translateY(-50%);
    @media (max-width: $breakpoint2) {
        width: 22px;
        height: 19px;
        margin-top: -4px;
    }
    @media (max-width: $breakpoint3) {
        width: 18px;
        height: 15px;
        margin-top: -3px;
    }
}
article.feature a[target=_blank] ~ h2::after {
    transform: translateY(-50%) rotate(-45deg);
}

/* ---------------------------------------------------------------------- SVG --- */
.feature svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: auto;
    max-width: calc(100% - 120px);
    transform: translate(-50%, -50%);
}
