/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// CONTACT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_contact';

/* --------------------------------------------------------------------- BODY --- */
#{$h} body {
    color: white;
    background-color: $grey9;
}

/* ------------------------------------------------------------------- HEADER --- */
#{$h} body > header::before {
    background-color: $grey9;
}

/* ------------------------------------------------------------------- FOOTER --- */
#{$h} footer {
    display: none;
}
