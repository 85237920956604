/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// RESET /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- WILDCARD --- */
*,
*:after,
*:before {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(black, 0);
}

/* ------------------------------------------------------------------ ELEMENT --- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    padding: 0;
    margin: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

/* ----------------------------------------------------------------- DOCUMENT --- */
body {
    line-height: 1;
    tap-highlight-color: rgba(black, 0);
}

/* --------------------------------------------------------------------- LIST --- */
ol,
ul {
    list-style: none;
}

/* -------------------------------------------------------------------- TABLE --- */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* --------------------------------------------------------------------- FORM --- */
input,
textarea,
button,
select {
    color: inherit;
    font-family: inhert;
    font-size: inherit;
    appearance: none;
}

select {
    text-indent: 0.01px;
    text-overflow: '';
    border: 0;
    border-radius: 0;
    option {
        font-family: sans-serif;
    }
}
select::-ms-expand {
    display: none;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 black;
}

/* -------------------------------------------------------------------- QUOTE --- */
blockquote,
q {
    quotes: none;
    &:before,
    &:after {
        content: '';
        content: none;
    }
}

/* ---------------------------------------------------------------- SUP / SUB --- */
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

/* --------------------------------------------------------------- CODE / PRE --- */
code,
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}
