/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// MEDIA /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- MEDIA --- */
.media {
    position: relative;
    overflow: hidden;
}

/* -------------------------------------------------------------------- IMAGE --- */
.media.image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.media.image div {
    background: inherit;
    transition: opacity 240ms ease;
    opacity: 0;
    @include position-fill(absolute);
}
.media.image.active div {
    opacity: 1;
}
.media.image img {
    width: 100%;
    vertical-align: top;
    opacity: 0;
}

/* -------------------------------------------------------------------- VIDEO --- */
.media.video {
    background-color: black;
}
.media.video::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-color: transparent transparent transparent white;
    border-style: solid;
    border-width: 20px 0 20px 34.6px;
    transform: translate(-50%, -50%);
    transition: opacity 160ms ease;
    cursor: pointer;
    opacity: 1;
    @media (max-width: $breakpoint3) {
        border-width: 12px 0 12px 20.8px;
    }
}
.media.video.active_load::after,
.media.video.active_play::after {
    opacity: 0;
}
.media.video iframe,
.media.video .media.image {
    @include position-fill(absolute);
}
.media.video .media.image {
    z-index: 1;
    transition: opacity 160ms ease;
    opacity: 1;
}
.media.video.active_play .media.image {
    opacity: 0;
    pointer-events: none;
}
